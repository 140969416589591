import { ColorType, GradientType } from './types/color-type'

export enum PlanSlug {
  COLLECT = 'virtual-office-collect',
  FORWARD = 'virtual-office-forward',
  MEETINGS = 'meeting-rooms',
  OFFICIAL_MAIL = 'official-government-mail',
  PHONE = 'virtual-office-phone',
  SCAN_LITE = 'virtual-office-scan-lite',
  SCAN_PRO = 'virtual-office-scan-pro',
}
export const planOrder = [
  'virtual-office-scan-lite',
  'virtual-office-scan-pro',
  'virtual-office-forward',
  'virtual-office-collect',
  'virtual-office-phone',
  'meeting-rooms',
  'official-government-mail',
]

export enum AddonSlug {
  DIRECTORS_SERVICE_ADDRESS = 'addon-directors-service-address',
  VIRTUAL_OFFICE_PHONE = 'addon-virtual-office-phone',
  TIDE_BUSINESS_BANKING = 'addon-tide-business-banking',
  INTRODUCTION_TO_AN_ACCOUNTANT = 'addon-introduction-to-an-accountant',
}

export const hideAddonsMapping = {
  [PlanSlug.PHONE]: true,
  [PlanSlug.COLLECT]: false,
  [PlanSlug.FORWARD]: false,
  [PlanSlug.MEETINGS]: false,
  [PlanSlug.MEETINGS]: false,
  [PlanSlug.OFFICIAL_MAIL]: false,
  [PlanSlug.SCAN_LITE]: false,
  [PlanSlug.SCAN_PRO]: false,
}

export const addonToProductMapping = {
  [PlanSlug.PHONE]: AddonSlug.VIRTUAL_OFFICE_PHONE,
  [PlanSlug.COLLECT]: null,
  [PlanSlug.FORWARD]: null,
  [PlanSlug.MEETINGS]: null,
  [PlanSlug.MEETINGS]: null,
  [PlanSlug.OFFICIAL_MAIL]: null,
  [PlanSlug.SCAN_LITE]: null,
  [PlanSlug.SCAN_PRO]: null,
}

export const planColors: Record<PlanSlug, ColorType> = {
  [PlanSlug.COLLECT]: 'purple3',
  [PlanSlug.FORWARD]: 'pink',
  [PlanSlug.MEETINGS]: 'teal',
  [PlanSlug.MEETINGS]: 'teal',
  [PlanSlug.OFFICIAL_MAIL]: 'blue',
  [PlanSlug.PHONE]: 'lavender',
  [PlanSlug.SCAN_LITE]: 'blue',
  [PlanSlug.SCAN_PRO]: 'purple2',
}

export const planGradients: Record<PlanSlug, GradientType> = {
  [PlanSlug.COLLECT]: 'gradient-purple',
  [PlanSlug.FORWARD]: 'gradient-pink',
  [PlanSlug.MEETINGS]: 'gradient-teal',
  [PlanSlug.OFFICIAL_MAIL]: 'gradient-blue',
  [PlanSlug.PHONE]: 'gradient-blue',
  [PlanSlug.SCAN_LITE]: 'gradient-blue',
  [PlanSlug.SCAN_PRO]: 'gradient-purple-picton-blue',
}

import { createContext, useContext, useState } from 'react'
import { getApi } from './api-common'
import { TAddon, TDuration, TPlanSummary } from './cms-types'
import { orderToEvent, planToEvent } from './utils/planToEvent'
import { addonToEvent } from './utils/addonToEvent'
import { AddonSlug, addonToProductMapping, PlanSlug } from './planSlugs'
import { AddressType } from './types/address-type'
import { Project } from './project'
import { TOrder, TRequire3ds } from './types/order-type'
import { getPriceIds } from 'components/util/getPriceIds'
import { coupon } from './useCalculatePrice'
import { BusinessType } from './types/business-type'
import { Utils } from './utils'

export type BagItem = {
  slug: PlanSlug | null
  duration: TDuration
  addons: AddonSlug[]
}

export type CheckoutAPIType = {
  reference_id?: string
  billing_request_id?: string
  payment_intent?: string
  customer_id?: string
  payment_type: string
  customer_email: string
  business: {
    business_type: string
    legal_name: string
    country_of_corporation: string
    trading_name: string | null
    company_number: string | null
    description: string | null
  }
  billing_address: AddressType
  address: AddressType
  product_id: string
  addons?: string[]
}

export type Require3dsType = (
  payment_intent: string,
  client_secret: string,
  customer_id: string,
) => Promise<{
  payment_intent?: string
  client_secret?: string
  customer_id: string
}>

export const ShoppingBagContext = createContext({
  setValue: (value: BagItem) => {
    // console.error('Tried to set bag', value)
  },
  value: { addons: [], duration: 'month', slug: null } as BagItem,
})

export default function () {
  const { setValue, value } = useContext(ShoppingBagContext)

  const [addonDetails, setAddonDetails] = useState<TAddon[]>([])

  const removePlan = (plan: TPlanSummary) => {
    if (value.slug) {
      getApi().trackEvent(
        'remove_from_cart_ga4',
        planToEvent(plan, value.duration),
      )

      setValue({ addons: value.addons || [], duration: 'month', slug: null })
    }
  }
  const clearBasket = () => {
    setValue({ addons: [], duration: 'month', slug: null })
  }
  const removePlanAddon = (addonSlug: string, data: TAddon) => {
    const newPlanAddons = addonDetails?.filter((v) => v.slug !== addonSlug)
    setAddonDetails(newPlanAddons)
    Utils.setProperties({
      checkout_offers: newPlanAddons?.map((v) => v.offer_category).join(','),
    })
    if (
      value.addons.find((v) => {
        return v === addonSlug
      })
    ) {
      getApi().trackEvent(
        'remove_from_cart_ga4',
        addonToEvent(data, value.duration),
      )
      setValue({
        ...value,
        addons: value.addons.filter((v) => {
          return v !== addonSlug
        }),
      })
    }
  }

  const addPlan = (
    slug: PlanSlug,
    duration: TDuration = 'month',
    summary: TPlanSummary,
  ) => {
    // @ts-ignore
    getApi().trackEvent('add_to_cart_ga4', planToEvent(summary, duration))

    if (
      // Attempting to add a plan that could also be an addon to a non-empty basket
      addonToProductMapping[slug] &&
      value.slug
    ) {
      //Only add the addon if it doesn't exist
      if (!value.addons.includes(addonToProductMapping[slug]!)) {
        setValue({
          ...value,
          addons: value.addons.concat([addonToProductMapping[slug]!]),
        })
      }
    } else {
      setValue({
        ...value,
        addons:
          // @ts-ignore
          value.slug &&
          addonToProductMapping[value.slug] &&
          !value.addons.includes(addonToProductMapping[value.slug]!)
            ? // Attempting to add a plan to a non-empty basket that has a product which could be an addon
              value.addons.concat([addonToProductMapping[value.slug]!])
            : value.addons,
        duration,
        slug,
      })
    }
  }

  const checkout = (
    data: CheckoutAPIType,
    plans: TPlanSummary[],
    addons: TAddon[],
    onRequire3ds: Require3dsType,
  ) => {
    const currentPlan = value.slug
    const currentAddons = value.addons

    const ids = getPriceIds(
      plans,
      addons,
      currentPlan,
      currentAddons,
      value.duration,
    )
    const planSummary = plans.find((v) => v.slug === value.slug)
    if (!ids?.product_id) {
      return Promise.reject('You have no products')
    }
    const body = {
      ...data,
      addons: ids.addons?.filter((v) => !!v),
      business: {
        ...data.business,
        legal_name:
          data.business.business_type === BusinessType.SOLE_TRADER
            ? data.business.trading_name
            : data.business.legal_name,
      },
      payment_type: coupon?.discountedTotal === 0 ? 'NONE' : data.payment_type,
      product_id: ids.product_id,
      reference_id: data.reference_id,
      voucher: coupon?.code,
    } as CheckoutAPIType

    return fetch(`${Project.api}checkout/`, {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response)
        } else {
          return Promise.reject(response.text())
        }
      })
      .then((res) => {
        return res.json()
      })
      .then(async (apiRes: TOrder | TRequire3ds) => {
        let res = apiRes
        if ((res as TRequire3ds).payment_intent) {
          res = res as TRequire3ds
          const { client_secret, customer_id, payment_intent } =
            await onRequire3ds(
              res.payment_intent,
              res.client_secret,
              res.customer_id,
            )
          if (!payment_intent) {
            throw 'Payment intent was not successful'
          }
          res = (await fetch(`${Project.api}checkout/confirm`, {
            body: JSON.stringify({
              ...body,
              client_secret,
              customer_id,
              payment_intent,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          })
            .then((response) => {
              // todo: standardise api response handling
              if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response)
              } else {
                return Promise.reject(response.text())
              }
            })
            .then((res) => {
              return res.json()
            })) as TOrder
        }
        res = res as TOrder
        if (res.order_id) {
          try {
            getApi().trackEvent(
              'purchase_ga4',
              orderToEvent(
                planSummary!,
                value.addons.map((v) =>
                  addons.find((addon) => addon.slug === v),
                ) as TAddon[],
                value.duration,
                res,
              ),
            )
          } catch (e) {
            console.error('Could not track purchase event', e)
          }
          getApi().storage.setItemSync('order', JSON.stringify(res))
          return res
        } else {
          throw 'Order was not created'
        }
      })
      .catch((e) => {
        throw e
      })
  }

  const updateDuration = (
    duration: TDuration = 'month',
    summary: TPlanSummary,
  ) => {
    // @ts-ignore
    getApi().trackEvent('add_to_cart_ga4', planToEvent(summary, duration))
    //todo: if existing basket or new product is virtual phone, replace with 020 add-on
    setValue({
      ...value,
      duration,
    })
  }

  const addPlanAddon = (addon: AddonSlug, data: TAddon) => {
    if (data.offer) {
      const newPlanAddons = addonDetails
        ?.filter((v) => v.slug !== addon)
        .concat([data])
      setAddonDetails(newPlanAddons)
      Utils.setProperties({
        checkout_offers: newPlanAddons?.map((v) => v.offer_category).join(','),
      })
    }
    if (value.addons.find((addonName) => addon === addonName)) {
      console.warn('Attempting to add already added add-on')
      return
    }

    getApi().trackEvent('add_to_cart_ga4', addonToEvent(data, value.duration))

    setValue({
      ...value,
      addons: value.addons.concat([addon]),
    })
  }

  const hasPlanInBag = (slug: string) => {
    return value.slug === slug
  }

  const itemsInBag = value.slug ? 1 + value.addons.length : 0

  return {
    addPlan,
    addPlanAddon,
    checkout,
    clearBasket,
    hasPlanInBag,
    itemsInBag,
    removePlan,
    removePlanAddon,
    updateDuration,
    value,
  }
}
